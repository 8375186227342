@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
html{
    scroll-behavior:smooth;
    
}
body {
    
    background-image: url(../public/dark_background.jpg);
    background-repeat: repeat;
	text-align: center;
	margin: 0 auto;
    
    width: 100%;
}

::-webkit-scrollbar {
    width: 10px;
  }
  
  
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
     
  }
   
  
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
header {
    height: max-content;
    width: 100%;
    overflow-x: hidden;
}
.home-image-div{
    background-image: url(../public/rodion-kutsaev-F573ZRbKOEw-unsplash\ \(1\)\ copy.jpg);
    background-size: cover;
    padding-top: 1px;
    height: 650px;
}

.navbar{
    justify-content: center;
    
}

.navbar a{
    text-decoration: none;
    color: white;
    font-size:20px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}
#navbar hr{
    background-color: white;
    border: 0px;
    height: 2px;
    max-width: 700px;
    width: 90%;
    margin: 0 auto;
}
#navbar{
    position: fixed;
    top: 0;
  width: 100%;
  display: block;
  transition: top 0.3s;
  z-index: 9;
}
.Name-div{
    text-align: left;
    
    margin: 30px;
   
    
}
.Name-div h1{
    font-family: 'Bebas Neue', cursive;
    font-size: 12vw;
    transition: ease;
    margin-left: 50px;
    color: white;
    position: fixed;
    left: 0px;
    line-height:0vw;
    margin-top: 150px;
}
#about-me{
    margin: 0px;
    margin-top: 20px;
    padding: 0px;
    width: 100%;
    position: relative;
    z-index: 1;
    
}

.about-me-img{
    
    background-image:url(./images/photo_2022-03-30_11-04-11.jpg);
    background-size: cover;
    min-height: 100vh;
}
.row{
    width: 100%;
    margin: 0px;
}
.about-me-text{
    background-image: url(../public/bright_background.jpg);
    font-family: 'Montserrat', sans-serif;
    color: white;
    padding: 30px;
    text-align: justify;
    text-justify:auto;
}
.about-me-text a{
    -webkit-animation: color 30s cubic-bezier(1,0,0,1) infinite;
  animation: color 30s cubic-bezier(1,0,0,1) infinite;
  animation-timing-function: ease-in-out;
-webkit-animation-timing-function: ease-in-out;
text-decoration: none;
font-size: larger;
margin: 0;
}
.languages-graphs{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    
}
.circle-graph{
    width: 50px;
    height: 50px;
    background-color: white;
    justify-content: center;
}
.lan-img img{
    width: 20px;
    height: 20px;
}
.headers{
    font-family: 'Montserrat', sans-serif;
    color: white;
    margin-top: 15px;
    margin-bottom: 10px;
}
.skills-name p{
    margin-bottom: 1px ;
    margin-top: 15px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bolder;
}
.progress-bar{
    -webkit-animation: background 30s cubic-bezier(1,0,0,1) infinite;
  animation: background 30s cubic-bezier(1,0,0,1) infinite;
  animation-timing-function: ease-in-out;
-webkit-animation-timing-function: ease-in-out;
}
@-webkit-keyframes background {
  0% { background-color: #f99; }
  33% { background-color: #9f9; }  
  67% { background-color: #99f; }
  100% { background-color: #f99; }
}

@keyframes background {
  0% { background-color: #f99; }
  33% { background-color: #9f9; }  
  67% { background-color: #99f; }
  100% { background-color: #f99; }
}
.vr{
    margin: 0px 5px;
}
.logo-container img{
    width: 50px;
}
.logo-container{
    float: right;
    background-color: rgb(142, 167, 218,0.5) ;
    margin-top: 440px;
    height: 150px;
    width: 150px;
    padding: 0px;
    transition: background-color .6s ease;
}
.logo-container:hover{
    background-color: rgb(142, 167, 218) ;
    transition: background-color .6s ease;
}
.logo-container a{
    margin:5px 0px;
}
.card{
    min-width: 45%;
    margin: 20px;
    padding: 10px;
    background-image: url(../public/bright_background.jpg);
}
.card-title{
    color: white;
    text-align: left;
    font-size: 45px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
}
.card-img-top{
    background: linear-gradient(rgba(161, 190, 32, 0.22) 0%, rgb(0, 0, 0) 99%);
}
.tool-logo{
    margin: 5px;
}
.tool-logo img{
    height: 50px;
    
}
.tool-container{
    width: max-content;
}
.card-body-inside{
    padding: 0px;
}
.card-text{
    color: white;
    text-align: left;
    font-family: 'Montserrat', sans-serif;
    font-size: larger;
    margin: 5px;
    padding: 10px;   
}
.project-section-title{
    color: white;
    font-family: 'Montserrat', sans-serif;
    
    font-size: 40px;
    margin: 20px;
    margin-top: 100px;
}
.btn-card{
    
    background-color:#8EA7DA;
    border-width: 0px;
    display: table;
}
.btn-card:active{
    background-color:#8EA7DA;
    border: none;
}
.btn-card:hover{
    background-color:#D6D4CC;
}
.btn-card:focus{
    border: none;
    background-color:#D6D4CC;   
}
.contact-section{
    background-color: #555;
    -webkit-animation: background 30s cubic-bezier(1,0,0,1) infinite;
  animation: background 30s cubic-bezier(1,0,0,1) infinite;
  animation-timing-function: ease-in-out;
-webkit-animation-timing-function: ease-in-out;
    margin: 80px 0px;
    padding: 50px;
}
.form-container{
    max-width: 1000px;
    padding: 40px;
    background-image: url(../public/dark_background.jpg);
    border-radius: 20px;
}
.form-row{
    margin: 0px;
}
.form-control{
    margin-bottom: 20px;
}
.contact-section h2{
    color: white;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 30px;
    font-size: 40px;
    
}
.form-label{
    color: white;
    float: left;
}
.occupation-section{
    float: right;
    align-content: center;
    margin: 10px;
    position: relative;
    transition: ease;
}
.occupation-section h3{
    
    -webkit-animation: color 30s cubic-bezier(1,0,0,1) infinite;
  animation: color 30s cubic-bezier(1,0,0,1) infinite;
  animation-timing-function: ease-in-out;
-webkit-animation-timing-function: ease-in-out;
    font-family: 'Bebas Neue', cursive;
    text-align: right;
    font-size:4vmax;
    margin: 5px 10px;
    left: 20px;
}
@-webkit-keyframes color {
    0% { color: #f99; }
    33% { color: #9f9; }  
    67% { color: #99f; }
    100% { color: #f99; }
  }
  
  @keyframes color {
    0% { color: #f99; }
    33% { color: #9f9; }  
    67% { color: #99f; }
    100% { color: #f99; }
  }
  .contact-me-form button{
    
    margin:15px;
    height: 50px;
    max-width: 200px;
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    font-weight: bolder;
  }
  .contact-section h3{
    color: white;
    font-family: 'Montserrat', sans-serif;
    margin:12px;
    padding: 10px;
  }